import { User } from '@src/API';
import { useAuthState } from '@src/providers/AuthProvider';
import { useListNotificationByUserIdQuery } from '@src/services/notificationHooks';
import { useGetUserQuery } from '@src/services/userHooks';
import { IUserService, RealUserService } from '@src/services/userService';
import dayjs from 'dayjs';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';

type NotificationProviderProps = {
  children: React.ReactNode;
};

export type NotificationContextValue = {
  hasNewNotification: boolean;
  hasUnreadCommissions: boolean;
  hasUnReadJobs: boolean;
  unreadNotificationNumber: number;
  updateLastTimeOpenNotification: () => void;
  updateReadCommissions: () => Promise<void>;
  updateReadJobs: () => Promise<void>;
};

const defaultValue = {
  hasNewNotification: false,
  hasUnreadCommissions: false,
  unreadNotificationNumber: 0,
  hasUnReadJobs: false,
  updateLastTimeOpenNotification: () => {
    return;
  },
  updateReadCommissions: async () => {
    return;
  },
  updateReadJobs: async () => {
    return;
  },
};

const NotificationContext = createContext<NotificationContextValue>(defaultValue);

// Local Storage Keys
const last_time_open_list_notification_local_storage_key = 'last_time_open_list_notification';
const has_unread_commissions = 'has_unread_commissions';
const has_unread_jobs = 'has_unread_jobs';

export default function NotificationProvider({ children }: NotificationProviderProps) {
  const userService: IUserService = new RealUserService();
  const { user } = useAuthState();
  const { data: listUserNotification } = useListNotificationByUserIdQuery(user?.id);
  const { data: userInfo } = useGetUserQuery(user?.id);

  const [hasNewNotification, setHasNewNotification] = useState<boolean>(false);
  const [unreadNotificationNumber, setUnreadNotificationNumber] = useState<number>(0);
  const [hasUnreadCommissions, setHasUnreadCommissions] = useState<boolean>(
    user?.hasUnreadCommissions || false,
  );
  const [hasUnReadJobs, setHasUnReadJobs] = useState<boolean>(user?.hasUnReadJobs || false);

  const [lastTimeOpenNotification, setLastTimeOpenNotification] = useState<string>(
    user?.last_time_open_list_notification || '',
  );

  // Handle Update From Other Tab
  const onStorageUpdate = (e: any) => {
    const { key, newValue } = e;
    if (key === last_time_open_list_notification_local_storage_key) {
      setLastTimeOpenNotification(newValue);
    }
    if (key === has_unread_commissions) {
      setHasUnreadCommissions(newValue);
    }
    if (key === has_unread_jobs) {
      setHasUnReadJobs(newValue);
    }
  };

  const processData = useCallback((user: User | null | undefined) => {
    if (user?.last_time_open_list_notification) {
      setLastTimeOpenNotification(user.last_time_open_list_notification);
      localStorage.setItem(
        last_time_open_list_notification_local_storage_key,
        user.last_time_open_list_notification,
      );
    }
    // has_unread_commissions
    if (user?.has_unread_commissions) {
      setHasUnreadCommissions(user.has_unread_commissions === 1);
      localStorage.setItem(has_unread_commissions, user.has_unread_commissions.toString());
    }
    // has_unread_jobs
    if (user?.has_unread_jobs) {
      setHasUnReadJobs(user.has_unread_jobs === 1);
      localStorage.setItem(has_unread_jobs, user.has_unread_jobs.toString());
    }
  }, []);

  /* Effects */

  useEffect(() => {
    setLastTimeOpenNotification(
      localStorage.getItem(last_time_open_list_notification_local_storage_key) || '',
    );
    setHasUnreadCommissions(localStorage.getItem(has_unread_commissions) === '1');
    setHasUnReadJobs(localStorage.getItem(has_unread_jobs) === '1');
    window.addEventListener('storage', onStorageUpdate);
    return () => {
      window.removeEventListener('storage', onStorageUpdate);
    };
  }, []);

  useEffect(() => {
    processData(userInfo);
  }, [userInfo, processData]);

  // Handle the lastest data from database for hasNewNotification
  useEffect(() => {
    if (!listUserNotification) {
      setHasNewNotification(false);
      return;
    }
    const arr = listUserNotification.list || [];
    const countUnread = arr.filter(({ is_read }) => is_read === 0).length;
    const lastNotification = arr[0];
    const lastTimeNotification = dayjs(lastNotification?.publication_date_time || '');
    console.log('QQ: Data: ', {
      lastTimeOpenNotification,
      lastTimeNotification,
    });
    const dateTimeOpenNotification = dayjs(lastTimeOpenNotification);
    setUnreadNotificationNumber(countUnread);
    if (lastTimeNotification.isValid() && lastTimeNotification.isAfter(dateTimeOpenNotification)) {
      setHasNewNotification(true);
    } else {
      setHasNewNotification(false);
    }
  }, [listUserNotification, lastTimeOpenNotification]);

  /* Functions */
  const updateLastTimeOpenNotification = () => {
    const now = new Date().toISOString();
    if (user?.id) {
      userService.updateUserLastTimeOpenListNotification(user.id);
    }
    localStorage.setItem(last_time_open_list_notification_local_storage_key, now);
    setLastTimeOpenNotification(now);
  };

  const updateReadCommissions = async () => {
    if (user?.id) {
      await userService.updateUserReadTheLatestCommissions(user.id);
    }
    localStorage.setItem(has_unread_commissions, '0');
    setHasUnreadCommissions(false);
  };

  const updateReadJobs = async () => {
    if (user?.id) {
      await userService.updateUserReadTheLatestJobs(user.id);
    }
    localStorage.setItem(has_unread_jobs, '0');
    setHasUnReadJobs(false);
  };

  const stateValues = {
    hasNewNotification,
    hasUnreadCommissions,
    hasUnReadJobs,
    unreadNotificationNumber,
    updateLastTimeOpenNotification,
    updateReadCommissions,
    updateReadJobs,
  };

  return (
    <NotificationContext.Provider value={stateValues}>{children}</NotificationContext.Provider>
  );
}

export const useNotificationState = (): NotificationContextValue => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw Error('Use useNotificationState in NotificationProvider');
  }
  return context;
};
