var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"nYmrZkZkJpkx4n2hzbwdL"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const environment = process.env.NEXT_PUBLIC_ENV_NAME || 'local';

if (environment === 'development') {
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      'https://572e9c0d87554aa9b234cddf35fdbbbc@o4504802006532096.ingest.sentry.io/4504802014724096',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    environment: 'development',
    ignoreErrors: [
      'ResizeObserver loop completed with undelivered notifications',
      'ResizeObserver',
    ],
  });
} else if (environment === 'production') {
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      'https://572e9c0d87554aa9b234cddf35fdbbbc@o4504802006532096.ingest.sentry.io/4504802014724096',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.25,
    environment: 'production',
    ignoreErrors: [
      'ResizeObserver loop completed with undelivered notifications',
      'ResizeObserver',
    ],
  });
}
