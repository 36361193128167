import { INotificationService, RealNotificationService } from '@src/services/notificationService';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

const notificationService: INotificationService = new RealNotificationService();

export const useListNotificationByUserIdQuery = (userId: string | null | undefined) => {
  return useQuery({
    // Query keys: name of service, name of method, arguments
    queryKey: ['notificationService', 'listNotificationByUserIdQuery', userId],
    refetchInterval: 1000 * 60 * 1, // 2 minutes
    queryFn: async () => {
      if (!userId) return null; // Have to return null, not undefined
      return await notificationService.listNotificationByUserId({
        userId,
        limit: 5,
      });
    },
    enabled: !!userId,
  });
};

export const useListNotificationByUserIdInfiniteQuery = (userId: string | null | undefined) => {
  return useInfiniteQuery({
    // Query keys: name of service, name of method, arguments
    queryKey: ['notificationService', 'listNotificationByUserIdInfiniteQuery', userId],
    queryFn: async ({ pageParam }) => {
      if (!userId) return null; // Have to return null, not undefined
      // console.log('useListNotificationByUserIdInfiniteQuery', userId, pageParam);
      return await notificationService.listNotificationByUserId({
        userId,
        nextToken: pageParam,
      });
    },
    getNextPageParam: (lastPage) => {
      return lastPage?.nextToken;
    },
    enabled: !!userId,
  });
};
